import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import template from './GestionUtilisateur.Template.vue';
import { Utilisateur, Adresse } from '@/models';
import { ApiService } from '@/services/base/ApiService';
import { DataTableHeader } from '@/shared/models';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import { Mutation } from 'vuex-class';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { PagingAndSorting } from '@/shared/models';
import { UtilisateurCriteria } from './models/UtilisateurCriteria.model';
import Confirm from '@/components/Confirm.vue';

@Component({
    ...template,
    name: 'GestionUtilisateur',
    components: {
        Confirm,
    }
})
export default class GestionUtilisateur extends mixins(GrilleMixin)  {
    public headers: DataTableHeader[] = [];
    public users: Utilisateur[];
    public loading: boolean = true;
    public canEdit: boolean = true;
    public dialog: boolean = false;
    public progress: number = 0;
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };

    /**
     * Le nombre total d'utilisateurs.
     */
    public totalUtilisateurs: number = 0;

    /**
     * Le critère de recherche de la grille.
     */
    public utilisateurCriteria: UtilisateurCriteria = new UtilisateurCriteria();

    // Modification de l'utilisateur du store.
    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR)
    private setUtilisateur: (utilisateur: Utilisateur) => void;

    constructor() {
        super();
        this.headers = [];
        this.users = [];
    }
    public created() {
        this.initialize();
        this.getData();
    }

    public initialize() {
        this.headers = [
            { text: 'Actif', sortable: false },
            { text: 'Prénom Nom', sortable: false },
            { text: 'Profil', sortable: false },
            { text: 'Raison sociale', sortable: false },
            { text: 'Adresse mail', sortBy: 'email' },
            { text: 'Téléphone', sortable: false },
        ];
        this.users = [];
    }

    /*
     * Récupération des données.
     */
    public getData() {
        this.utilisateurCriteria.pagingAndSorting = PagingAndSorting.buildFromPaginationObject(this.pagination);
        this.getAllUsers(this.utilisateurCriteria);
    }

    public rechercheUtilisateurs() {
        // Réinitialise la pagination avant de lancer la recherche.
        this.utilisateurCriteria.pagingAndSorting = new PagingAndSorting();
        this.getData();
    }

    public getAllUsers(criteria: UtilisateurCriteria): void {
        this.loading = true;
        const fetchAllUsersApi = new ApiService<Utilisateur>('utilisateur/obtenirTous');

        fetchAllUsersApi.getWhere(criteria.queryString).then((response) => {
            this.users = response.data.data;
            this.totalUtilisateurs = response.data.totalCount;
            this.$set(this.pagination, 'totalItems', this.totalUtilisateurs);
        })
        .finally(() => this.loading = false);

    }
    public editItem(idAModifier: number): void {
        this.resetUtilisateurStore();
        (this.$router as any).push({ name: 'modifier-utilisateur', params: { id: idAModifier } });
    }

    // Reset l'utilisateur avant d'aller sur le formulaire d'ajout d'un utilisateur.
    public resetUtilisateurStore(): void {
        this.setUtilisateur(new Utilisateur());
    }

    /*
     * Renvoie une adresse pour l'affichage
     */
    public displayAdresse(adresse: Adresse): string {
        let display = '';
        if (adresse) {
            display = `${adresse.numeroVoie}` +
            `${adresse.typeVoie ? adresse.typeVoie.libelle : ''}` +
            `${adresse.nomVoie} ${adresse.complementVoie ? adresse.complementVoie : ''} \n` +
            `${adresse.ville ? adresse.ville.codePostal : adresse.codePostalLibre }` +
            `${adresse.ville ? adresse.ville.libelle : adresse.villeLibre }`;
        }
        return display;
    }

    /**
     * Envoi de mail de réinitialisation de mot de passes en masse à tous les utilisateurs actif.
     *
     */
    public resetPasswordBulk() {
        this.dialog = true;
        const userService = new ApiService<boolean>('utilisateur/sendPasswordResetEmailBulk');
        userService.post(null).finally(() => {
            this.dialog = false;
        });
    }

    /**
     * Confirmation
     */
    public confirmResetingBulk() {
        this.$refs.confirm.open('Confirmation', 'Confirmez-vous l\'envoi d\'e-mails de réinitialisation des mots de passes pour tous les utilisateurs actifs', {})
        .then((resultConfirm) => {
            if (resultConfirm) {
                this.resetPasswordBulk();
            }
        });
    }
}
