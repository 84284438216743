import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Pagination } from '../models/Pagination.model';
import { Profils } from '../enums';

/**
 * Mixin regroupant les différentes méthodes utilisées par les grilles.
 */
@Component
export class GrilleMixin extends Vue {

    // Met à disposition l'énumération des profils.
    public Profils = Profils;

    /**
     * La pagination synchronisée avec la grille.
     */
    public pagination: Pagination = new Pagination();

    /*
     * Méthode à définir dans les composants héritant de ce mixin pour la récupération des données.
     */
    // tslint:disable-next-line
    public getData() {
    }

    /**
     * Change la direction et/ou la colonne du tri.
     * @param columnName : le nom de la colonne à trier.
     * @param callBackMethod : la méthode à appeler une fois que les paramètres du tri sont mis à jour.
     */
    public changeSort(columnName: string, callBackMethod: () => void) {
        // On ne fait rien si le nom de colonne n'est pas renseigné.
        if (!columnName) {
            return;
        }

        // On change la direction quand on re clique sur une colonne.
        /*if (this.pagination.sortBy === columnName) {
            this.pagination.descending = this.pagination.descending === false;
        } else {
            // On change la colonne de tri.
            this.pagination.descending = false;
            this.pagination.sortBy = columnName;
        }*/

        /*
        if (callBackMethod) {
            // On recharge les données.
            callBackMethod();
        }
        */
    }

    /**
     * Watcher sur la pagination.rowsPerPage.
     * @param value : nouvelle valeur de la propriété pagination.rowsPerPage.
     * @param oldValue : ancienne valeur de la propriété pagination.rowsPerPage.
     */
    @Watch('pagination')
    public onRowsPerPageChanged(value: number, oldValue: number) {
        this.getDataPagination(value, oldValue);
    }
    
    /**
     * Récupère les datas en fonction de la pagination.
     */
    private getDataPagination = (value: Pagination | number, oldValue: Pagination | number) => {
        //On ordonne les propriétés car JSON.stringify ne garantit pas l'ordre des propriétés
        if (JSON.stringify(value, Object.keys(value).sort()) !== JSON.stringify(oldValue, Object.keys(oldValue).sort())) {
            this.getData();
        }
    }
}
